<template>
  <el-dialog
    title="流转记录"
    :visible.sync="dialogVisible"
    width="70%"
    :before-close="handleClose"
  >
    <div class="transactionRecord">
      <div class="searchItem">
        <el-form
          :model="form"
          label-position="center"
          label-width="100px"
          inline
          ref="form"
        >
          <el-form-item label="流出方：">
            <el-input
              style="width: 190px"
              v-model="form.crf"
              placeholder="流出方姓名"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="流入方：">
            <el-input
              style="width: 190px"
              v-model="form.srf"
              placeholder="流入方姓名"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="成交起止时间">
            <!-- <el-date-picker
              v-model="TimeValue"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker> -->

            <el-date-picker
            v-model="Time"
            placeholder="选择开始日期"
            size="small"
            type="date"
            value-format="yyyy-MM-dd"
            :clearable="false"
             @change="changeTime"
          >
          </el-date-picker>
               至
          <el-date-picker
            v-model="Time1"
            placeholder="选择结束日期"
            size="small"
            type="date"
            value-format="yyyy-MM-dd"
            :clearable="false"
            @change="changeTime1"

          >
          </el-date-picker>
          </el-form-item>

          <div>
            <el-button class="query-btn" type="primary" @click="search"
              >搜索</el-button
            > <el-button class="query-btn" type="primary" @click="clear"
              >重置</el-button
          >
          </div>
        </el-form>
      </div>
      <div class="tableList">
        <table-page
          :data="tableList"
          :columns="columns"
          :currentPage="query.current"
          :pageSize="query.size"
          :total="total"
          :border="true"
          @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        >
          <template slot="flowAreaMu" slot-scope="scope">
            <span>{{ scope.row.flowAreaMu&&scope.row.flowAreaMu.toFixed(2) + "亩" }}</span>
          </template>

          <template slot="lznx" slot-scope="scope">
            <span>{{ scope.row.flowAreaMu&&scope.row.lznx + "年" }}</span>
          </template>

          <template slot="lzlx" slot-scope="scope">
            <span>{{ getLzlx(scope.row.lzlx) }}</span>
          </template>
          <template slot="cqlxbh" slot-scope="scope">
            <span>{{ getcqlx(scope.row.cqlxbh) }}</span>
          </template>

          <template  slot="operation" slot-scope="scope">
            <el-button  type="text" size="small" @click="editClick(scope.row)"
              >合同及备案详情</el-button
            >
          </template>
        </table-page>
      </div>
    </div>
    <el-dialog
      width="50%"
      title="合同文档查看"
      :visible.sync="innerVisible"
      append-to-body
      innerVisible
      :destroy-on-close="false"
    >
      <div class="tableInner">
        <div class="title" v-if=" contractList.uploader ||contractList.uploadTime ">
          <div class="l" >
            <span>上传人：{{ contractList.uploader }}</span>
          </div>
          <div class="r">
            <span>上传时间：{{ contractList.uploadTime }}</span>
          </div>
        </div>
        <!-- 切换按钮 -->


        <div class="tabs" >
          <div v-for="(item, index) in tabList" :key="index">
            <span @click="detailBtn(index)">{{item.title}}</span>

          </div>
          <!-- <div v-for="(item,index) in 5" :key="index">
            {{ index }}
          </div> -->
        </div>
        <div class="ShowList" v-if="fileList.length">
          <div class="innerTable">
            <el-upload
              v-if="innerVisible"
              class="upload-demo"
              action=""
              :accept="'.jpg, .JPG, .png, .PNG, .jpeg, .JPEG, WebP,'"
              list-type="picture-card"
              :file-list="fileList"
              multiple
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <el-image
                  ref="preview"
                  style="height: 115px"
                  class="el-upload-list__item-thumbnail"
                  :src="file.imgUrl"
                  :preview-src-list="innerTableList"
                  alt=""
                  :initial-index=0
                />
                <span>{{ file.FilesName }}</span>
                <span class="el-upload-list__item-actions">
                  <span
                    v-if="!file.isShow"
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                  >
                    <i class="el-icon-download"></i>
                  </span>
                  <!-- <span
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span> -->
                </span>
              </div>
            </el-upload>
          </div>
        </div>
        <div v-else style="width: 100%; text-align: center">暂无数据</div>
      </div>
    </el-dialog>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// 图片下载
import { download } from "@/api/ChargeUp";
import TablePage from "@/components/table/table-page";
import { selectCjTime, selectContractPic,selectFilingNumPic } from "@/api/add";
import { ResourceAsset } from "./tableColum";

export default {
  data() {
    return {
      dialogVisible: true,
      innerVisible: false,
      tabList: [{
        title:'合同归档文件',
        index:'1',

      },{
        title:'合同备案照片',
        index:'2'
      }],
      query: {
        current: 1,
        size: 10,
      },
      baseUrl: window.globalUrl.BASE_API,
      total: 0,
      form: {
        crf: "",
        srf: "",
      },
      tableList: [],
      // TimeValue: ["",""],
      Time:'',
      Time1:'',
      columns: ResourceAsset,
      areaCode: "",


      tabsIndex: "",
      xmbsm: "",
      ImgList: [],
      baseUrl: window.globalUrl.BASE_API,
      contractList: [],
      innerTableList: [],
      fileList: [],
      startTime:'',
      endTime:'',
    };
  },
  components: {
    TablePage,
  },
  watch: {
    fileList: {
      handler(newVal, oldVal) {
        // 检查每个文件对象的 name 属性
        // this.$nextTick(() => {
        // 在 DOM 更新之后执行回调函数
        newVal.forEach((file) => {
          if (file.FilesName.toLowerCase().endsWith("pdf")) {
            // 修改符合条件的文件对象的 url 属性
            file.imgUrl =
              "https://tse2-mm.cn.bing.net/th/id/OIP-C.or-hk0X7YoPwI6IzdXK7sgHaHa?w=168&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7";
            file.isShow = "true";
          }
        });
        // 在这里可以根据实际需求执行相应操作
      },
      deep: true, // 深度监听数组内部元素的变化
    },
  },
  methods: {
    back() {
      this.$emit("closeDialog", false);
    },
    handleClose() {
      this.$emit("closeDialog", false);
    },

    //分页单页切换
    handleCurrentChange(page) {
      this.query.current = page;
      this.getList();
    },
    //分页总页数切换
    handleSizeChange(size) {
      this.query = {
        current: 1,
        size: size,
      };
      this.getList();
    },
    // 获取流转类型
    getLzlx(type) {
      let res = "";
      if (type == 0) {
        res = "出租";
      } else if (type == 1) {
        res = "入股";
      } else if (type == 2) {
        res = "转让";
      } else if (type == 3) {
        res = "出让";
      }
      return res;
    },
    getcqlx(type) {
      let res = "";
      if (type == "01") {
        res = "集体建设用地";
      } else if (type == "02") {
        res = "耕地";
      } else if (type == "03") {
        res = "草地";
      } else if (type == "04") {
        res = "林地";
      } else if (type == "05") {
        res = "四荒地";
      } else if (type == "06") {
        res = "宅基地及农房";
      } else if (type == "07") {
        res = "农业生产设施设备";
      } else if (type == "08") {
        res = "农业知识产权";
      } else if (type == "09") {
        res = "园地";
      } else if (type == "10") {
        res = "水域";
      }
      return res;
    },
    //回显
    initObj(obj,Time,Time1) {

      this.startTime=Time
      this.endTime=Time1
      this.areaCode = obj.xzqbh;
      this.Time=Time
      this.Time1=Time1
      this.getList();
    },
    // 切换时间
    changeTime(val) {
      console.log(val);
        this.Time = val;
      // this.initData();
      this.getList();
    },
    // 切换时间
    changeTime1(val) {
      // console.log(val);
      // if (val == 1) {
      //   this.startTime = val[0];
      // } else {
        this.Time1 = val;
      // }

      // this.initData();
      this.getList();
    },
    // 搜索
    async search() {
      this.getList()
    },
    //重置
    async clear(){
      this.form.crf=""
      this.form.srf=""
      // this.TimeValue=this.BeforeTime
      let startTime=this.startTime
      let endTime=this.endTime
      this.Time=startTime
      this.Time1=endTime
      console.log( this.startTime,'this.startTime')
      console.log( this.endTime,'this.endTime')
      this.getList()
    },
  // 流转记录列表
   async getList() {
      let params = {
        ...this.query,
        areaCode: this.areaCode,
        cjStartTime: this.Time? this.Time:"",
        cjEndTime: this.Time1? this.Time1:'',
        crf: this.form.crf,
        srf: this.form.srf,
      };
      let res = await selectCjTime(params);
      this.tableList = res.data.records;
      this.total = Number(res.data.total);
    },
    //放大
    handlePictureCardPreview(file) {

      // 点击展示点击的图片
      console.log('预览的图片嘛',file)

      let abc = []
      this.fileList.forEach((its) => {
            if (!its.isShow) {
              abc.push(its.url2);
            }
          });
          //保存修改完的图片预览列表
       let newList = []
       let i=''
       let num =abc.length // 原数组的长度
          abc.some((item, index) => {
        if (file.url2 === item) {
          console.log('相等')
            newList.push(item)
            i=index
        }
      })

      newList.push(...abc.slice(i + 1, num)) // 压入顺序在当前图片之后的图片url
      newList.push(...abc.slice(0, i)) // 压入顺序在当前图片之前的图片url
      abc = newList // 重新赋值
      this.innerTableList=newList
      this.$nextTick(() => {
          this.$refs.preview.clickHandler();
      });
      console.log( this.innerTableList,' this.innerTableList')
      // let abc = [];
      // console.log(this.fileList, "查看this. this.fileList");
      // this.fileList.forEach((its) => {
      //   if (!its.isShow) {
      //     abc.push(its.imgUrl);
      //   }
      // });
      // this.innerTableList = abc;
      // this.$nextTick(() => {
      //   this.$refs.preview.clickHandler();
      // });
      // console.log(this.innerTableList,'查看')




    //只预览一张图片
      // let abc=[]
      //   abc.push(file.imgUrl)
      // this.innerTableList=abc
      // this.$nextTick(() => {
      //   this.$refs.preview.clickHandler();
      // });

    },
    //下载
    async handleDownload(file) {
      let itemList = file;
      let res = await download({
        // this.baseUrl + "admin/file/get?ossFilePath=" +
        ossFilePath:
          itemList.url2,
      });
      if (res) {
        let blob = res;
        let ToName = itemList.FilesName;
        const fileName = ToName;
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, {
            type: "application/pdf||doc||docx||xls||xlsx||zip||7z",
          })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
    //合同及备案详情
    editClick(obj) {
      console.log(JSON.parse(JSON.stringify( obj)) ,'查看obj')
      this.fileList = [];
      this.InnerList = [];
      this.innerVisible = true;
      this.xmbsm = obj.xmbsm;
      this.selectContractPic();
    },
    //点击切换
    detailBtn(index) {
      this.tabsIndex = index;
      switch (index) {
        case 0:
          // 查看备案表
          this.selectContractPic();
          break;
        case 1:
          //备案照片
          this.selectFilingNumPic();

        default:
          break;
      }
    },
    //回显备案照片
  async  selectFilingNumPic(){
    this.fileList = [];
      this.InnerList = [];
      let params = {
        xmbsm: this.xmbsm,

      };
      let res = await selectFilingNumPic(params);
      let list = res.data;
      list.picUrl = JSON.parse(list.picUrl);
      console.log("88888",list.picUrl);
    //转换数据格式
    const newPicList = [];
    console.log(list,'查看list')
    list.picUrl&&list.picUrl.forEach((item) => {
        newPicList.push({
          FilesName: item.FilesName,
          imgUrl: this.baseUrl + "admin/file/get?ossFilePath=" + item.thumImgUrl,
          url2:this.baseUrl + "admin/file/get?ossFilePath=" +  item.imgUrl,
        });
      });
         //拼接数组
         list.picUrl = newPicList;
         console.log("********",newPicList);
      //全部的数据
      this.contractList = list;
      //这是回显的数据
      this.fileList = newPicList;
  },
    //回显合同归档文件
    async selectContractPic() {
      this.fileList = [];
      this.InnerList = [];
      let params = {
        xmbsm: this.xmbsm,
      };
      let res = await selectContractPic(params);
      let list = res.data;
      list.picUrl = JSON.parse(list.picUrl);
      //转换数据格式
      const newPicList = [];
     list.picUrl.forEach((item) => {
        newPicList.push({
          FilesName: item.FilesName,
          imgUrl: this.baseUrl + "admin/file/get?ossFilePath=" + item.thumImgUrl,
          url2: this.baseUrl + "admin/file/get?ossFilePath=" + item.imgUrl,
        });
      });

      //拼接数组
      list.picUrl = newPicList;
      //全部的数据
      this.contractList = list;
      //这是回显的数据
      this.fileList = newPicList;
      //这是需要预览的图片
      // for(let i=0;i<this.fileList.length;i++){
      //  this.innerTableList[i]=this.fileList[i].imgUrl
      // }

    },

    submit(){
      this.$emit("closeDialog", false);
    },
    cancel(){
      this.$emit("closeDialog", false);
    }
  },
};
</script>
<style lang="less" scoped>
/deep/.transactionRecord {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .searchItem {
    .el-form {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.tabs {
  width:100%;
  height:100rpx;
  display: flex;
  margin: 20px 0px;

  span {
    margin-right: 20px;
    border: solid 2px #ccc;
    padding: 5px 5px;
    cursor: pointer;

  }
}
.ShowList {
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    tr {
      td {
        .box {
          width: 150px;
          height: 100px;
          overflow: hidden;
          padding: 5px 5px;
          .Imgs {
            width: 232px;
            .el-image {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
.tableInner {
  .title {
    display: flex;
    justify-content: space-around;
  }
}
/deep/.el-upload--picture-card {
  display: none;
}
</style>
>
