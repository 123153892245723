<template>
	<!-- 横向排名占比柱状图 -->
	<div class="rank-chart">
		<MyChart :options="options" />
	</div>
</template>

    <script>
import MyChart from '@/components/echart/echart.vue'
import * as echarts from 'echarts'
export default {
	props: {
		xData: {
			type: Array,
			default: () => {
				return []
			},
		},
		seriesData: {
			type: Array,
			default: () => {
				return []
			},
		},
		legend: {
			type: Array,
			default: () => {
				return []
			},
		},
	},
	components: {
		MyChart,
	},
	data() {
		return {
			options: {},
		}
	},
	watch: {
		xData: {
			immediate: true,
			handler(val) {
				if (val && val.length > 0) {
					this.initChart()
				}
			},
		},
		seriesData: {
			immediate: true,
			handler(val) {
				if (val && val.length > 0) {
					this.initChart()
				}
			},
		},
	},
	methods: {
		initChart() {
			var xData = this.xData
			let seriesArr = []
			this.seriesData.forEach(item => {
				seriesArr.push({
					name: item.name,
					type: 'bar',
					barWidth: '18%',
					stack: item.stack,
					yAxisIndex: item.stack == 'cjJe' ? 0 : 1,
					emphasis: {
						focus: 'series',
					},
					data: item.data,
					label: {
						normal: {
							show: true,
							position: 'inside',
							color: '#333333',
							fontWeight: '550',
							formatter: function (params) {
								return `${params.value + (item.stack == 'cjArea' ? '(亩)' : '(万元)')}`
							},
						},
					},
				})
			})
			this.options = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
					formatter: function (params) {
						let res = `${params[0].name}<br/>`
						params.map(item => {
							if (item.data) {
								res += `<span style="background: ${
									item.color
								}; height:10px; width: 10px; border-radius: 50%;display: inline-block;margin-right:10px;"></span> ${
									item.seriesName
								}${item.seriesIndex%2 != 1 ? `面积` : `金额`}  ：${item.data}${
									item.seriesIndex%2 != 1 ? `亩` : `万元`
								}<br/>`
							}
						})
						return res
					},
				},
				color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452'],
				legend: {},
				grid: {
					top: '15%',
					left: '3%',
					right: '4%',
					bottom: '1%',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						data: xData,
						// axisLabel: {
						// 	interval: 0,
						// 	rotate: 45,
						// 	//倾斜度 -90 至 90 默认为0
						// 	textStyle: {
						// 		color: '#333333',
						// 	},
						// },
					},
				],
				yAxis: [
					{
						axisLine: {
							show: true, //隐藏X轴轴线
							lineStyle: {
								color: 'rgba(220,220,220,0.3)',
							},
						},
						axisTick: {
							show: false, //隐藏X轴刻度
						},
						axisLabel: {
							textStyle: {
								color: '#333',
							},
						},
						splitLine: {
							//网格线
							show: true,
							lineStyle: {
								color: 'rgba(220,220,220,0.3)',
							},
						},
					},
					{
						type: 'value', // 右边的y轴
						name: '面积（亩）',
						nameTextStyle: {
							fontSize: 14,
							color: '#333',
							padding: [10, 0, 10, 0],
						},
					},
				],
				series: seriesArr,
			}
		},
	},
}
</script>

    <style lang="less" scoped>
.rank-chart {
	width: 100%;
	height: 100%;
}
</style>
