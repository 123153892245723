
export const ResourceAsset = [{
  prop: "xmbh",
  width:110,
  label: "项目编号",
},
{
  prop: "crf",
  width:150,
  label: "流出方",
},
{
  prop: "flowAreaMu",
  label: "面积",
  template: true,
},
{
  prop: "lznx",
  width:70,
  label: "年限",
  template: true,
},
{
  prop: "lzlx",
  width:100,
  label: "交易类型",
  template: true,

},
{
  prop: "cjTime",
  width:120,
  label: "合同签订时间",
},
{
  prop: "cqlxbh",
  width:100,
  label: "产权类型",
  template: true,

},
{
  prop: "cjzj",
  width:120,
  label: "成交金额(元)",
},
{
  prop: "srf",
  width:250,
  label: "流入方",
},
{
  prop: "operation",
  label: "操作",
  template: true,
},
]
